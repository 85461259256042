import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { accessTokenProperty, getRefreshToken, getValidAccessToken } from '../utils/auth'
import { ERole } from './enum'
import {
  IBillMessageReadRequest,
  IBillRequest,
  IBillUpdateRequest,
  IClient,
  IClientUpdateRequest,
  ICreateBillAttachmentRequest,
  ICreateBillMessageRequest,
  IOrgBillActionRequest,
  ICreateClientInvitationRequest,
  ICreateEmailIntegrationResponse,
  ICreateOrgInvitationRequest,
  IDeleteBillAttachmentRequest,
  IDeleteEmailAttachmentRequest,
  IHeaders,
  IListBillEventResponse,
  IListBillMessageResponse,
  IListBillRequest,
  IListBills,
  IListBillSummaryResponse,
  IListClientsResponse,
  IListClientUsersResponse,
  IListEntityResponse,
  IListGuidWithName,
  IListInboxEmailRequest,
  IListInboxEmails,
  IListInboxEmailSummary,
  IListInvitationsResponse,
  IListMaskUsers,
  IListOrganizationsResponse,
  IListOrgUsersResponse,
  IListVendors,
  ILoginInput,
  IOrganization,
  IOrgUser,
  IUpdateClientInvitationRequest,
  IUpdateClientUserRequest,
  IUpdateEntityRequest,
  IUpdateOrgInvitationRequest,
  IUpdateOrgUserRequest,
  IUpdateVendorRequest,
  IUser,
  IUserRequest,
  IClientBillActionRequest,
  IBill,
  IUserClientResponse,
  IOrgUserClientRequest,
  IOrgUserClientAssignRequest,
  IListOrgUserClients,
  IListOrgUserClientsResponse,
  IBillMessageSummaryResponse,
  IListFileAttachments,
  ICreateEmailAttachmentRequest,
  IListDesignatedEntityResponse,
  IListDesignatedVendorResponse,
  ICreateDesignatedEntityRequest,
  ICreateDesignatedVendorRequest,
  IUpdateDesignatedEntityRequest,
  IUpdateDesignatedVendorRequest,
  IDestroyDesignatedVendorRequest,
  IDestroyDesignatedEntityRequest,
  IListOrgGroupUserResponse,
  IOrgGroupUserGuid,
  IAddUserInGroupRequest,
  IListGroupPermissionResponse,
  ICreateOrgGroupPermissionCreateRequest,
  IOrgGroupPermissionSlug,
  IUpdateOrganizationRequest,
  IQBORedirectResponse,
  IQBOCompanyResponse,
  ICreateBillNoteRequest,
  IListBillNotesResponse,
  ICreateOrgUserClientRequest,
  ICreateOrgUserRequest,
  ICreateEntityRequest,
  IQBDCompanyFileResponse,
  IQBDCompanyFileUpdateRequest,
  IListQBDCompanyFileResponse,
  IListAccounts,
  IListReportsResponse,
  IListReportsRequest,
  IReportsQuantitiesByYearRequest,
  IReportsQuantitiesResponse,
  IReportsQuantitiesByMonthRequest,
  ICreateOrgEmailAttachmentRequest,
  IClientBillActionBulkApproveRequest,
  IBillsByPaidDateResponse,
  I2FAResendCodeRequest,
  IListDesigneeResponse,
  IReportSummaryResponse,
  IListUserOrgGroupPermission,
  IClientEntityBillSummaryRequest,
  IListClientEntityBillSummaryResponse,
  INotifications,
  ICommitmentsRequest,
  ICommitmentsTrackerRequest,
  IListCommitments,
  ICommitments,
  ICommitmentBill,
  ICommitmentBillRequest,
  IListCommitmentBillsResponse,
  ICommitmentBillRequestPayload,
  ICommitmentNoteRequest,
  IListCommitmentNotesResponse,
  ICreateCommitmentRequest,
  ICommitmentCashOutflow,
  IListCommitmentBillRequest,
  IClientMonthlyOverview,
  IGenerateCommitmentsTemplateRequest,
  IGenerateCommitmentsTemplatePayload,
  IListCustomerResponse,
  ICustomerRequest,
  IListAccountBalance,
  IListUserResponse,
  IListAssignedClientsResponse,
  IQBListResponse,
  IQBBillData,
  IListAccountBalanceResponse,
  IReminderRequest,
  IListReportReminders,
  IListReconciliationWorkflow,
  ICreateReminderRequest,
  IReminder,
  IListReportReminderHistory,
  ICreateReminderHistoryRequest,
  IReminderHistory,
  IClientReportRequest,
  IListDesigneeReportResponse,
  IClientInAppNotificationRequest,
  IBalanceSnapshotRequest,
  IBulkExcludedVendorRequest,
  IBulkExcludedDivisionRequest,
  IUpdateEntityAccountRequest,
  IListBillDownloadRequest,
  IReport,
  IListUserRequest,
  IListEntityRequest,
  IListVendorRequest,
  IAuditHistoryRequest,
  IListAuditHistoryResponse,
  IGroupRequest,
  IOrgReverseBillPayment,
  IBulkCreateOrgGroupPermissionCreateRequest,
  IListBillAttachmentResponse,
  IUserDashboardChart,
  IListAssociateCommitments,
  ICreateAuditHistoryRequest,
  IAuditHistory,
  IBillDashboardChart,
  IReportReminderNoteRequest,
  IListReminderNotesResponse,
  ITaskListRequest,
  IListTaskItems,
  ITaskItemsSummaryResponse,
  ITaskItemRequest,
  IAssignedOnboarding,
  IListTasksResponse,
  ITaskCreateRequest,
  IListTaskRuleResponse,
  ITaskRuleCreateRequest,
  IOutlookEmailRequest,
  IPropertyListRequest,
  IListPropertyResponse,
  IPropertyCreateRequest,
  IClientContactRequest,
  IListClientContactResponse,
  IClientContactCreate,
  IListOrgUserClientUsersResponse,
  IDocsRequest,
  IListOnboardingDocsResponse,
  IOnboardingDocs,
  IListClientBankContactResponse,
  IClientBankContactCreate,
  ITaskItems,
  IOrgBillReviseRequest,
  IUpdateParentVendorRequest,
  ICreateParentVendorRequest,
  IListParentVendorsRequest,
  ICreateVendorRequest,
  IUserFeatures,
  IVendor,
  IListVendorsOfParentVendorRequest,
  IRecordPaymentRequest,
  IReportSummaryRequest,
  IListCommitmentDownloadRequest,
  IListClientDownloadRequest,
  IListPreBillDownloadRequest,
  IPlumbPin,
  IListPlumbPinsRequest,
  ICreatePlumbPin,
  ICommitmentsMatrix,
  IReconciliationSummaryRequest,
  IReconciliationSummaryResponse,
  IReconciliationTaskBulkUpdate,
  IListReconciliationDownloadRequest,
} from './interface'

let axiosInstance: AxiosInstance
let unauthenticatedAxiosInstance: AxiosInstance
let isRefreshing = false
let refreshSubscribers: Array<(data: { access: string }) => void> = []

const baseURL: string = process.env.REACT_APP_SERVER

const API_URL = {
  AUTH: '/v1/auth',
  ORGANIZATIONS: '/v1/accounts/organizations',
  ORG_BILLING: '/v1/billing/organizations',
  ORG_PARENT_VENDORS: '/v1/billing/parent-vendors',
  ORG_VENDORS: '/v1/billing/vendors',
  INBOX_ORG: '/v1/inbox/organizations',
  INBOX_CLIENT: '/v1/inbox/clients',
  CLIENTS: '/v1/accounts/clients',
  CLIENTS_BILLING: '/v1/billing/clients',
  INTEGRATIONS: '/v1/integrations',
  REPORTS_CLIENT: '/v1/reports/clients',
  REPORTS_ORG: '/v1/reports/organizations',
  NOTIFICATIONS: 'v1/notifications',
  CONFIG: 'v1/versions/config',
  PLUMB_PINS: 'v1/accounts/clients',
}

const getHeaders = async () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const headers: IHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Client-Timezone': timezone,
  }
  try {
    const token = await getValidAccessToken()
    if (token) {
      headers.Authorization = `Bearer ${token}`
    }
    return headers
  } catch (e) {
    openSignInWindow()
  }
}

const openSignInWindow = () => {
  window.localStorage.clear()
  window.location.href = '/login'
  window.location.reload()
}

const getMultiPartHeaders = async () => {
  const headers = await getHeaders()
  return {
    ...headers,
    'Content-Type': 'multipart/form-data',
  }
}

const getAxiosInstance = async (): Promise<AxiosInstance> => {
  if (!axiosInstance) {
    axiosInstance = axios.create({
      baseURL,
      headers: await getHeaders(),
    })
    axiosInstance.interceptors.response.use(
      (response) => {
        return response
      },
      async function (error) {
        const originalRequest = error.config
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true
          try {
            const data = await createTokenRefresh(getRefreshToken())
            if (!!data && !!data.access) {
              window.localStorage.setItem(accessTokenProperty, data.access)
              axios.defaults.headers.common['Authorization'] = `Bearer ${data.access}`
              return axiosInstance.request(originalRequest)
            } else {
              return Promise.reject(error)
            }
          } catch (e) {
            openSignInWindow()
          }
        }
        return Promise.reject(error)
      }
    )
    axiosInstance.interceptors.request.use(
      async (config) => {
        config.headers = await getHeaders()
        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  }
  return axiosInstance
}

const getUnauthenticatedAxiosInstance = (): AxiosInstance => {
  if (!unauthenticatedAxiosInstance) {
    unauthenticatedAxiosInstance = axios.create({
      baseURL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
  }
  return unauthenticatedAxiosInstance
}

const getRequest = async <T, K = any>(
  url: string,
  params: K = null as any,
  responseType: any = 'json'
): Promise<T> => {
  const instance = await getAxiosInstance()
  const response = await instance.get(url, { params, responseType })
  return response.data
}

const postRequest = async <T, K = any, R = any>(
  url: string,
  payload: T,
  params: K = null as any,
  useAuth = true
): Promise<R> => {
  const instance = useAuth ? await getAxiosInstance() : getUnauthenticatedAxiosInstance()
  const response = await instance.post(url, payload, params as AxiosRequestConfig)
  return response.data
}

const patchRequest = async <T, K = any>(url: string, payload: T, params: K = null as any) => {
  const instance = await getAxiosInstance()
  const response = await instance.patch(url, payload, { params })
  return response.data
}

const deleteRequest = async (url: string, payload?: any) => {
  const instance = await getAxiosInstance()
  const response = await instance.delete(url, {
    data: payload,
  })
  return response.data
}

const putRequest = async <T>(url: string, payload?: T) => {
  const instance = await getAxiosInstance()
  const response = await instance.put(url, payload)
  return response.data
}

// ORGANIZATION CLIENT API

export const listClients = async (
  guid: string,
  disabled: boolean,
  page?: number,
  pageSize?: number,
  search?: string,
  ordering?: string,
  is_onboarding_completed?: boolean
) =>
  await getRequest<
    IListClientsResponse,
    {
      disabled: number
      page?: number
      page_size?: number
      search?: string
      ordering?: string
      is_onboarding_completed?: boolean
    }
  >(`${API_URL.ORGANIZATIONS}/${guid}/clients`, {
    page,
    page_size: pageSize,
    search,
    disabled: disabled ? 1 : 0,
    ordering,
    is_onboarding_completed,
  })

export const createClient = async ({
  orgGuid,
  name,
  primary_contact,
  is_onboarding_completed,
  plumb_service,
  bp_ff_amount,
  foa_ff_amount,
  total_ff_amount,
}: ICreateOrgUserClientRequest) =>
  await postRequest<{
    name: string
    primary_contact?: IUserRequest
    is_onboarding_completed?: boolean
    plumb_service?: string[]
    bp_ff_amount?: number
    foa_ff_amount?: number
    total_ff_amount?: string
  }>(`${API_URL.ORGANIZATIONS}/${orgGuid}/clients`, {
    name,
    primary_contact,
    is_onboarding_completed,
    plumb_service,
    bp_ff_amount,
    foa_ff_amount,
    total_ff_amount,
  })

export const retrieveClient = async (orgGuid: string, clientGuid: string) =>
  await getRequest<IClient>(`${API_URL.ORGANIZATIONS}/${orgGuid}/clients/${clientGuid}`)

export const partialUpdateClient = async ({
  orgGuid,
  clientGuid,
  ...payload
}: IClientUpdateRequest) =>
  await patchRequest(`${API_URL.ORGANIZATIONS}/${orgGuid}/clients/${clientGuid}`, payload)

export const destroyClient = async ({
  orgGuid,
  clientGuid,
}: {
  orgGuid: string
  clientGuid: string
}) => await deleteRequest(`${API_URL.ORGANIZATIONS}/${orgGuid}/clients/${clientGuid}`)

// ORGANIZATION CLIENT INVITATIONS API

export const listOrgClientInvitations = async (orgGuid: string, page: number, search: string) =>
  await getRequest<IListInvitationsResponse>(
    `${API_URL.ORGANIZATIONS}/${orgGuid}/client-invitations`,
    {
      page,
      search,
    }
  )

export const createOrgClientInvitation = async ({
  orgGuid,
  email,
}: {
  orgGuid: string
  email: string
}) => await postRequest(`${API_URL.ORGANIZATIONS}/${orgGuid}/client-invitations`, { email })

export const destroyOrgClientInvitation = async ({
  orgGuid,
  invGuid,
}: {
  orgGuid: string
  invGuid: string
}) => await deleteRequest(`${API_URL.ORGANIZATIONS}/${orgGuid}/client-invitations/${invGuid}`)

export const resendOrgClientInvitation = async ({
  orgGuid,
  invGuid,
}: {
  orgGuid: string
  invGuid: string
}): Promise<undefined> =>
  await postRequest(
    `${API_URL.ORGANIZATIONS}/${orgGuid}/client-invitations/${invGuid}/resend`,
    null
  )

// ORGANIZATION STAFF INVITATION
export const sendStaffWelcomeEmail = async ({
  guid,
  userGuid,
}: {
  guid: string
  userGuid: string
}): Promise<undefined> =>
  await postRequest(
    `${API_URL.ORGANIZATIONS}/${guid}/users/${userGuid}/send-staff-invitation`,
    null
  )

// ORGANIZATION API

export const listOrganizations = async (page: number) =>
  await getRequest<IListOrganizationsResponse, { page: number }>(`${API_URL.ORGANIZATIONS}`, {
    page,
  })

export const retrieveOrganization = async (orgGuid: string) =>
  await getRequest<IOrganization>(`${API_URL.ORGANIZATIONS}/${orgGuid}`)

export const createOrganization = async (name: string) =>
  await postRequest<{ name: string }>(`${API_URL.ORGANIZATIONS}`, { name })

export const partialUpdateOrganization = async ({
  orgGuid,
  ...payload
}: IUpdateOrganizationRequest) => await patchRequest(`${API_URL.ORGANIZATIONS}/${orgGuid}`, payload)

// ORG USER API

export const listOrgUsers = async (orgGuid: string, params: IListUserRequest) =>
  await getRequest<IListOrgUsersResponse, IListUserRequest>(
    `${API_URL.ORGANIZATIONS}/${orgGuid}/users`,
    params
  )

export const retrieveOrgUser = async (orgGuid: string, userGuid: string) =>
  await getRequest<IOrgUser>(`${API_URL.ORGANIZATIONS}/${orgGuid}/users/${userGuid}`)

export const createOrgUser = async ({ orgGuid, user, role }: ICreateOrgUserRequest) =>
  await postRequest<{ user: IUserRequest; role: ERole }>(
    `${API_URL.ORGANIZATIONS}/${orgGuid}/users`,
    {
      user,
      role,
    }
  )

export const partialUpdateOrgUser = async ({
  orgGuid,
  userGuid,
  payload,
}: {
  orgGuid: string
  userGuid: string
  payload: IUpdateOrgUserRequest
}) => await patchRequest(`${API_URL.ORGANIZATIONS}/${orgGuid}/users/${userGuid}`, payload)

export const destroyOrgUser = async ({
  orgGuid,
  userGuid,
}: {
  orgGuid: string
  userGuid: string
}) => await deleteRequest(`${API_URL.ORGANIZATIONS}/${orgGuid}/users/${userGuid}`)

export const enableOrgUser = async ({ orgGuid, userGuid }: { orgGuid: string; userGuid: string }) =>
  await putRequest(`${API_URL.ORGANIZATIONS}/${orgGuid}/users/${userGuid}/enable`)

export const createBulkGroup = async ({ orgGuid, userGuid, groupGuids }: IGroupRequest) =>
  await postRequest<{ group_guids: string[] }>(
    `${API_URL.ORGANIZATIONS}/${orgGuid}/users/${userGuid}/groups/bulk-create`,
    {
      group_guids: groupGuids,
    }
  )

// ORG USER INVITATION API

export const listOrgInvitations = async (orgGuid: string, page: number, search: string) =>
  await getRequest<IListInvitationsResponse>(
    `${API_URL.ORGANIZATIONS}/${orgGuid}/user-invitations`,
    { page, search }
  )

export const createOrgInvitation = async ({ orgGuid, invitation }: ICreateOrgInvitationRequest) =>
  await postRequest(`${API_URL.ORGANIZATIONS}/${orgGuid}/user-invitations`, invitation)

export const updateOrgInvitation = async ({
  orgGuid,
  invGuid,
  invitation,
}: IUpdateOrgInvitationRequest) =>
  await patchRequest(`${API_URL.ORGANIZATIONS}/${orgGuid}/user-invitations/${invGuid}`, invitation)

export const destroyOrgInvitation = async ({
  orgGuid,
  invGuid,
}: {
  orgGuid: string
  invGuid: string
}) => await deleteRequest(`${API_URL.ORGANIZATIONS}/${orgGuid}/user-invitations/${invGuid}`)

export const resendOrgInvitation = async ({
  orgGuid,
  invGuid,
}: {
  orgGuid: string
  invGuid: string
}): Promise<undefined> =>
  await postRequest(`${API_URL.ORGANIZATIONS}/${orgGuid}/user-invitations/${invGuid}/resend`, null)

// AUTH API

export const createTokenObtainPair = async (input: ILoginInput) =>
  await postRequest<ILoginInput>(`${API_URL.AUTH}/token`, input, {}, false)

const subscribeTokenRefresh = (callback: (data: { access: string }) => void) => {
  // add the request to queue for waiting access_token
  refreshSubscribers.push(callback)
}

export const createTokenRefresh = async (refresh: string) => {
  if (!refresh || !refresh.trim()) {
    throw new Error('Invalid refresh token')
  }

  if (isRefreshing) {
    // If a token refresh is already in progress, return a Promise that resolves when it's done.
    // This prevents multiple request to the server to refresh endpoint
    return new Promise<{ access: string }>((resolve) => {
      subscribeTokenRefresh((data: { access: string }) => {
        resolve(data)
      })
    })
  }

  isRefreshing = true

  try {
    const response = await postRequest(`${API_URL.AUTH}/token/refresh`, { refresh }, null, false)

    // process all requests that is also waiting for the new access_token
    refreshSubscribers.forEach((callback) => callback(response))
    refreshSubscribers = [] // Reset the subscriber array
    isRefreshing = false
    return response
  } catch (error) {
    isRefreshing = false
    return Promise.reject(error)
  }
}

export const blacklistTokens = async (refresh: string, access: string) =>
  await postRequest(`${API_URL.AUTH}/token/blacklist`, { refresh, access }, null, false)

export const renewPasswordClaim = async (refresh: string) =>
  await postRequest(`${API_URL.AUTH}/token/renew-claims`, { refresh }, null, false)

export const requestLogin = (input: ILoginInput) =>
  postRequest(`${API_URL.AUTH}/login`, input, {}, false)

export const createLoginCode = (payload: { ephemeral_token: string; code: string }) =>
  postRequest(`${API_URL.AUTH}/login/code`, payload, undefined, false)

export const createMask = async ({ guid }: { guid: string }) =>
  await postRequest(`${API_URL.AUTH}/mask`, { guid })

export const listMaskUsers = async (page: number, search: string, pageSize?: number) =>
  await getRequest<IListMaskUsers>(`${API_URL.AUTH}/mask/users`, {
    page,
    search,
    page_size: pageSize,
  })

export const passwordResetCreate = async ({ email }: { email: string }) =>
  await postRequest(`${API_URL.AUTH}/password-reset`, { email }, null, false)

export const passwordResetConfirm = async (payload: { password: string; token: string }) =>
  await postRequest(`${API_URL.AUTH}/password-reset/confirm`, payload, null, false)

export const passwordResetValidateToken = async (payload: { token: string }) =>
  await postRequest(`${API_URL.AUTH}/password-reset/validate-token`, payload, null, false)

export const activate2FA = async ({ method, value }: { method: string; value: string }) =>
  await postRequest(`${API_URL.AUTH}/mfa/${method}/activate`, { value })

export const request2FACode = async ({ method }: { method: string }) =>
  await postRequest(`${API_URL.AUTH}/mfa/code/request`, { method })

export const change2FaMethod = async ({
  method,
  code,
}: {
  method: string
  code: string | number
}) => await postRequest(`${API_URL.AUTH}/mfa/change-primary-method`, { method, code })

export const confirm2FA = async ({
  method,
  code,
  refresh,
}: {
  method: string
  code: string
  refresh: string
}) => await postRequest(`${API_URL.AUTH}/mfa/${method}/activate/confirm`, { code, refresh })

export const deactivate2FA = async ({ method }: { method: string }) =>
  await postRequest(`${API_URL.AUTH}/mfa/${method}/deactivate`, null)

export const resendCodeMFA = async ({ method, ephemeralToken }: I2FAResendCodeRequest) =>
  await postRequest(
    `${API_URL.AUTH}/mfa/${method}/resend-code`,
    {
      ephemeral_token: ephemeralToken,
    },
    undefined,
    false
  )

export const acceptTerms = async () =>
  await postRequest(`${API_URL.AUTH}/users/me/accept-terms`, null)

// USER API

export const partialUpdateUser = async ({
  guid,
  userRequest,
}: {
  guid: string
  userRequest: IUserRequest
}) => await patchRequest(`${API_URL.AUTH}/users/${guid}`, userRequest)

export const retrieveUser = async (guid: string) =>
  await getRequest<IUser>(`${API_URL.AUTH}/users/${guid}`)

export const retrieveUserFeatures = async (guid: string | undefined) =>
  await getRequest<IUserFeatures[]>(`${API_URL.AUTH}/users/${guid}/features`)

export const uploadAvatarUpdate = async ({ guid, file }: { guid: string; file: any }) => {
  const instance = await getAxiosInstance()
  const response = await instance.put(`${API_URL.AUTH}/users/${guid}/upload-avatar`, file, {
    headers: await getMultiPartHeaders(),
  })
  return response.data
}

export const removeAvatar = async ({ guid }: { guid: string }) =>
  await postRequest(`${API_URL.AUTH}/users/${guid}/remove-avatar`, {})

// CLIENT BILL API

export const listClientBills = async (clientGuid: string, payload: IListBillRequest) =>
  await getRequest<IListBills>(`${API_URL.CLIENTS_BILLING}/${clientGuid}/bills`, payload)

export const retrieveClientBill = async (clientGuid: string, billGuid: string) =>
  await getRequest<IBill>(`${API_URL.CLIENTS_BILLING}/${clientGuid}/bills/${billGuid}`)

export const updateRejectClientBillAction = async ({
  clientGuid,
  billGuid,
  action,
  reject_reason = undefined,
  reject_other_description = '',
}: IClientBillActionRequest) =>
  await postRequest(`${API_URL.CLIENTS_BILLING}/${clientGuid}/bills/${billGuid}/workflow`, {
    action,
    reject_reason,
    reject_other_description,
  })

export const updateApproveClientBillAction = async ({
  clientGuid,
  billGuid,
  action,
}: IClientBillActionRequest) =>
  await postRequest(`${API_URL.CLIENTS_BILLING}/${clientGuid}/bills/${billGuid}/workflow`, {
    action,
  })

export const updateBulkApproveClientBillAction = async ({
  clientGuid,
  requests,
}: IClientBillActionBulkApproveRequest) =>
  await postRequest(
    `${API_URL.CLIENTS_BILLING}/${clientGuid}/bills/workflows/bulk-approve-reject`,
    {
      requests,
    }
  )

export const clientBillMessageSummary = async (clientGuid: string) =>
  await getRequest<IBillMessageSummaryResponse>(
    `${API_URL.CLIENTS_BILLING}/${clientGuid}/messages/summary`
  )

export const listClientGlobalMessages = async (clientGuid: string) =>
  await getRequest<IListBillMessageResponse>(`${API_URL.CLIENTS_BILLING}/${clientGuid}/messages`)

export const listClientBillMessages = async (clientGuid: string, billGuid: string, page?: number) =>
  await getRequest<IListBillMessageResponse>(
    `${API_URL.CLIENTS_BILLING}/${clientGuid}/bills/${billGuid}/messages`,
    { page }
  )

export const createClientBillMessage = async ({
  guid: clientGuid,
  billGuid,
  body,
}: ICreateBillMessageRequest) =>
  await postRequest(`${API_URL.CLIENTS_BILLING}/${clientGuid}/bills/${billGuid}/messages`, { body })

export const markClientBillMessageAsRead = async ({
  guid: clientGuid,
  billGuid,
  messageGuid,
}: IBillMessageReadRequest) =>
  await postRequest(
    `${API_URL.CLIENTS_BILLING}/${clientGuid}/bills/${billGuid}/messages/${messageGuid}/mark-as-read`,
    undefined
  )

export const createClientBill = ({
  clientGuid,
  payload,
}: {
  clientGuid: string
  payload: IBillRequest
}): Promise<IBill> =>
  postRequest<IBillRequest>(`${API_URL.CLIENTS_BILLING}/${clientGuid}/bills`, payload)

export const clientBillSummaryMetrics = (clientGuid: string, payload: IListBillRequest) =>
  getRequest<IListBillSummaryResponse>(
    `${API_URL.CLIENTS_BILLING}/${clientGuid}/bills/summary-metrics`,
    payload
  )

export const getClientBillsByPaidDate = async (clientGuid: string) =>
  await getRequest<IBillsByPaidDateResponse>(
    `${API_URL.CLIENTS_BILLING}/${clientGuid}/bills/by-paid-date`
  )

// CLIENT ADVANCED SEARCH
export const listAdvancedSearchBills = async (clientGuid: string, payload: IListBillRequest) =>
  await getRequest<IListBills>(
    `${API_URL.CLIENTS_BILLING}/${clientGuid}/bills/advanced-search`,
    payload
  )

export const exportAdvancedSearchBills = async (
  clientGuid: string,
  payload: IListBillDownloadRequest
) =>
  await postRequest<IListBillDownloadRequest, { responseType: string }, Blob>(
    `${API_URL.CLIENTS_BILLING}/${clientGuid}/bills/advanced-search`,
    payload,
    {
      responseType: 'blob',
    }
  )

export const listOrgAdvancedSearchBills = async (orgGuid: string, payload: IListBillRequest) =>
  await getRequest<IListBills>(`${API_URL.ORG_BILLING}/${orgGuid}/bills/advanced-search`, payload)

export const exportOrgAdvancedSearchBills = async (
  orgGuid: string,
  payload: IListBillDownloadRequest
) =>
  await postRequest<IListBillDownloadRequest, { responseType: string }, Blob>(
    `${API_URL.ORG_BILLING}/${orgGuid}/bills/advanced-search`,
    payload,
    {
      responseType: 'blob',
    }
  )

// ORG BILL API

export const listBills = async (orgGuid: string, payload: IListBillRequest) =>
  await getRequest<IListBills>(`${API_URL.ORG_BILLING}/${orgGuid}/bills`, payload)

export const retrieveOrgBill = async (orgGuid: string, billGuid: string) =>
  await getRequest<IBill>(`${API_URL.ORG_BILLING}/${orgGuid}/bills/${billGuid}`)

export const createOrgBill = async ({
  orgGuid,
  payload,
}: {
  orgGuid: string
  payload: IBillRequest
}): Promise<IBill> => {
  return await postRequest<IBillRequest>(`${API_URL.ORG_BILLING}/${orgGuid}/bills`, payload)
}

export const updateOrgBill = async ({
  orgGuid,
  billGuid,
  payload,
}: {
  orgGuid: string
  billGuid: string
  payload: IBillUpdateRequest
}): Promise<IBill> => {
  return await patchRequest<IBillUpdateRequest>(
    `${API_URL.ORG_BILLING}/${orgGuid}/bills/${billGuid}`,
    payload
  )
}

export const listEntitiesDivisions = async (
  orgGuid: string,
  entityGuid: string,
  page: number,
  page_size: number,
  archived?: boolean,
  search?: string
) =>
  await getRequest<IListGuidWithName>(
    `${API_URL.ORG_BILLING}/${orgGuid}/entities/${entityGuid}/divisions?search=${search || ''}`,
    {
      page,
      page_size,
      archived: archived,
    }
  )

export const listEntitiesAccounts = async (
  orgGuid: string,
  entityGuid: string,
  page: number,
  pageSize: number,
  archived?: boolean,
  search?: string,
  assignableToBill = true
) =>
  await getRequest<IListAccounts>(
    `${API_URL.ORG_BILLING}/${orgGuid}/entities/${entityGuid}/accounts?search=${search || ''}`,
    {
      page,
      page_size: pageSize,
      archived: archived,
      assignable_to_bill: assignableToBill,
    }
  )

export const listEntitiesPaymentTerms = async (orgGuid: string, entityGuid: string, page: number) =>
  await getRequest<IListGuidWithName>(
    `${API_URL.ORG_BILLING}/${orgGuid}/entities/${entityGuid}/payment-terms`,
    {
      page,
    }
  )

export const listEntitiesVendors = async (
  orgGuid: string,
  entityGuid: string,
  params: IListVendorRequest
) =>
  await getRequest<IListVendors>(
    `${API_URL.ORG_BILLING}/${orgGuid}/entities/${entityGuid}/vendors`,
    params
  )

export const partialUpdateVendor = async ({
  orgGuid,
  entityGuid,
  vendorGuid,
  payload,
}: IUpdateVendorRequest) =>
  await patchRequest(
    `${API_URL.ORG_BILLING}/${orgGuid}/entities/${entityGuid}/vendors/${vendorGuid}`,
    payload
  )

export const addBulkExcludedVendor = async ({
  clientGuid,
  entityGuid,
  userGuid,
  requests,
}: IBulkExcludedVendorRequest) =>
  await postRequest(
    `${API_URL.CLIENTS}/${clientGuid}/entities/${entityGuid}/designees/${userGuid}/excluded-vendors`,
    requests
  )

export const addBulkExcludedClass = async ({
  clientGuid,
  entityGuid,
  userGuid,
  requests,
}: IBulkExcludedDivisionRequest) =>
  await postRequest(
    `${API_URL.CLIENTS}/${clientGuid}/entities/${entityGuid}/designees/${userGuid}/excluded-divisions`,
    requests
  )

export const listBillSummary = async (orgGuid: string, params: IListBillRequest) =>
  await getRequest<IListBillSummaryResponse>(
    `${API_URL.ORG_BILLING}/${orgGuid}/bills/summary-metrics`,
    params
  )

export const updateOrgBillAction = async ({
  orgGuid,
  billGuid,
  ...payload
}: IOrgBillActionRequest) =>
  await postRequest(`${API_URL.ORG_BILLING}/${orgGuid}/bills/${billGuid}/workflow`, payload)

export const updateOrgBillRevise = async ({ guid, billGuid, ...payload }: IOrgBillReviseRequest) =>
  await postRequest(`${API_URL.ORG_BILLING}/${guid}/bills/${billGuid}/workflow/revise`, {
    reason: payload.reason,
  })

export const reverseBillPayment = async ({ orgGuid, billGuid }: IOrgReverseBillPayment) =>
  await postRequest(
    `${API_URL.ORG_BILLING}/${orgGuid}/bills/${billGuid}/workflow/reverse-payment`,
    {}
  )

export const billEventList = async (orgGuid: string, billGuid: string, page: number) =>
  await getRequest<IListBillEventResponse>(
    `${API_URL.ORG_BILLING}/${orgGuid}/bills/${billGuid}/events`,
    { page }
  )

export const billMessageList = async (orgGuid: string, billGuid: string, page: number) => {
  return await getRequest<IListBillMessageResponse>(
    `${API_URL.ORG_BILLING}/${orgGuid}/bills/${billGuid}/messages`,
    { page }
  )
}

export const createOrgBillMessage = async ({
  guid: orgGuid,
  billGuid,
  body,
}: ICreateBillMessageRequest) =>
  await postRequest(`${API_URL.ORG_BILLING}/${orgGuid}/bills/${billGuid}/messages`, { body })

export const listBillNotes = async (orgGuid: string, billGuid: string, page: number) => {
  return await getRequest<IListBillNotesResponse>(
    `${API_URL.ORG_BILLING}/${orgGuid}/bills/${billGuid}/notes`,
    { page }
  )
}

export const createBillNote = async ({ guid: orgGuid, billGuid, body }: ICreateBillNoteRequest) =>
  await postRequest(`${API_URL.ORG_BILLING}/${orgGuid}/bills/${billGuid}/notes`, { body })

export const markOrgBillMessageAsRead = async ({
  guid: orgGuid,
  billGuid,
  messageGuid,
}: IBillMessageReadRequest) =>
  await postRequest(
    `${API_URL.ORG_BILLING}/${orgGuid}/bills/${billGuid}/messages/${messageGuid}/mark-as-read`,
    undefined
  )

export const createBillAttachment = async ({
  guid: orgGuid,
  billGuid,
  file,
}: ICreateBillAttachmentRequest) => {
  const instance = await getAxiosInstance()
  const response = await instance.post(
    `${API_URL.ORG_BILLING}/${orgGuid}/bills/${billGuid}/attachments`,
    file,
    {
      headers: await getMultiPartHeaders(),
    }
  )
  return response.data
}

export const destroyBillAttachment = async ({
  guid: orgGuid,
  billGuid,
  baGuid,
}: IDeleteBillAttachmentRequest) =>
  await deleteRequest(`${API_URL.ORG_BILLING}/${orgGuid}/bills/${billGuid}/attachments/${baGuid}`)

export const orgBillMessageSummary = async (orgGuid: string) =>
  await getRequest<IBillMessageSummaryResponse>(
    `${API_URL.ORG_BILLING}/${orgGuid}/messages/summary`
  )

export const listOrgBillMessages = async (orgGuid: string) =>
  await getRequest<IListBillMessageResponse>(`${API_URL.ORG_BILLING}/${orgGuid}/messages`)

export const retrieveClientEntityBillSummary = async (
  orgGuid: string,
  payload: IClientEntityBillSummaryRequest
) =>
  await getRequest<IListClientEntityBillSummaryResponse, IClientEntityBillSummaryRequest>(
    `${API_URL.ORG_BILLING}/${orgGuid}/clients/entity-totals`,
    payload
  )

export const listOrgCustomers = async (
  orgGuid: string,
  entityGuid: string,
  payload: ICustomerRequest
) =>
  await getRequest<IListCustomerResponse>(
    `${API_URL.ORG_BILLING}/${orgGuid}/entities/${entityGuid}/customers`,
    payload
  )

// ORG CLIENT REPORTS
export const listOrgClientReportsSummary = async (
  orgGuid: string,
  payload: IReportSummaryRequest
) =>
  await getRequest<IReportSummaryResponse>(
    `${API_URL.REPORTS_ORG}/${orgGuid}/reports/summary`,
    payload
  )

// INBOX API

export const listInboxEmails = async (orgGuid: string, payload: IListInboxEmailRequest) =>
  await getRequest<IListInboxEmails>(`${API_URL.INBOX_ORG}/${orgGuid}/emails`, payload)

export const listInboxEmailSummaries = async (orgGuid: string, clients?: string) =>
  await getRequest<IListInboxEmailSummary>(
    `${API_URL.INBOX_ORG}/${orgGuid}/emails/summary-metrics`,
    {
      clients,
    }
  )

export const archiveEmail = async (orgGuid: string, emailGuid: string) =>
  await deleteRequest(`${API_URL.INBOX_ORG}/${orgGuid}/emails/${emailGuid}`)

export const unarchiveEmail = async (orgGuid: string, emailGuid: string) =>
  await putRequest(`${API_URL.INBOX_ORG}/${orgGuid}/emails/${emailGuid}/unarchive`, {})

export const listOrgEmailAttachments = async (orgGuid: string, emailGuid: string, page?: number) =>
  await getRequest<IListFileAttachments>(
    `${API_URL.INBOX_ORG}/${orgGuid}/emails/${emailGuid}/attachments`,
    {
      page,
    }
  )

export const destroyEmailAttachment = async ({
  orgGuid,
  emailGuid,
  eaGuid,
}: IDeleteEmailAttachmentRequest) =>
  await deleteRequest(`${API_URL.INBOX_ORG}/${orgGuid}/emails/${emailGuid}/attachments/${eaGuid}`)

export const listClientEmailAttachments = async (
  clientGuid: string,
  emailGuid: string,
  page?: number
) =>
  await getRequest<IListFileAttachments>(
    `${API_URL.INBOX_CLIENT}/${clientGuid}/emails/${emailGuid}/attachments`,
    {
      page,
    }
  )

export const createInboxEmail = ({
  subject,
  body,
  clientGuid,
  is_prebill_auto_approved,
}: {
  subject: string
  body: string
  clientGuid: string
  is_prebill_auto_approved?: boolean
}) =>
  postRequest(`${API_URL.INBOX_CLIENT}/${clientGuid}/emails`, {
    subject,
    body,
    is_prebill_auto_approved,
  })

export const createInboxEmailAttachment = async ({
  clientGuid,
  emailGuid,
  file,
}: ICreateEmailAttachmentRequest) => {
  const instance = await getAxiosInstance()
  const response = await instance.post(
    `${API_URL.INBOX_CLIENT}/${clientGuid}/emails/${emailGuid}/attachments`,
    file,
    {
      headers: await getMultiPartHeaders(),
    }
  )
  return response.data
}

export const createOrgInboxEmail = ({
  subject,
  body,
  clientGuid,
  orgGuid,
}: {
  subject: string
  body: string
  clientGuid: string
  orgGuid: string
}) => postRequest(`${API_URL.INBOX_ORG}/${orgGuid}/emails`, { subject, body, client: clientGuid })

export const createOrgInboxEmailAttachment = async ({
  orgGuid,
  emailGuid,
  file,
}: ICreateOrgEmailAttachmentRequest) => {
  const instance = await getAxiosInstance()
  const response = await instance.post(
    `${API_URL.INBOX_ORG}/${orgGuid}/emails/${emailGuid}/attachments`,
    file,
    {
      headers: await getMultiPartHeaders(),
    }
  )
  return response.data
}

// CLIENT API

export const listAccountClients = async ({ page }: { page: number }) =>
  await getRequest<IListClientsResponse>(`${API_URL.CLIENTS}`, { page })

export const listAccountAssignedClients = async ({ page }: { page: number }) =>
  await getRequest<IListAssignedClientsResponse>(`${API_URL.CLIENTS}/assigned`, { page })

export const listAccountClientsEntities = async (clientGuid: string, params: IListEntityRequest) =>
  await getRequest<IListEntityResponse>(`${API_URL.CLIENTS}/${clientGuid}/entities`, params)

export const createEntity = async ({
  clientGuid,
  payload,
}: {
  clientGuid: string
  payload: ICreateEntityRequest
}) => await postRequest(`${API_URL.CLIENTS}/${clientGuid}/entities`, payload)

export const partialEntityUpdate = async ({
  clientGuid,
  entityGuid,
  payload,
}: {
  clientGuid: string
  entityGuid: string
  payload: IUpdateEntityRequest
}) => await patchRequest(`${API_URL.CLIENTS}/${clientGuid}/entities/${entityGuid}`, payload)

export const enableClient = async ({
  orgGuid,
  clientGuid,
}: {
  orgGuid: string
  clientGuid: string
}) => await putRequest(`${API_URL.ORGANIZATIONS}/${orgGuid}/client/${clientGuid}`)

export const disableClient = async ({
  orgGuid,
  clientGuid,
}: {
  orgGuid: string
  clientGuid: string
}) => await deleteRequest(`${API_URL.ORGANIZATIONS}/${orgGuid}/client/${clientGuid}`)

export const listClientAccountBalanceList = async (
  clientGuid: string,
  {
    entityGuid,
    page,
    page_size,
    search,
  }: {
    entityGuid: string
    page?: number
    page_size?: number
    search?: string
  }
) =>
  await getRequest<IListAccountBalanceResponse>(
    `${API_URL.CLIENTS_BILLING}/${clientGuid}/entities/${entityGuid}/account-balance-list`,
    {
      page,
      page_size,
      search,
    }
  )

export const listClientEntitiesVendors = async (
  clientGuid: string,
  entityGuid: string,
  params: IListVendorRequest
) =>
  await getRequest<IListVendors>(
    `${API_URL.CLIENTS_BILLING}/${clientGuid}/entities/${entityGuid}/vendors`,
    params
  )

export const listClientEntitiesDivisions = async (
  orgGuid: string,
  entityGuid: string,
  page: number,
  page_size: number,
  search?: string,
  archived?: boolean
) =>
  await getRequest<IListGuidWithName>(
    `${API_URL.CLIENTS_BILLING}/${orgGuid}/entities/${entityGuid}/divisions?search=${search || ''}`,
    {
      page,
      page_size,
      archived: archived,
    }
  )

export const listClientEntitiesAccounts = async (
  clientGuid: string,
  entityGuid: string,
  page: number,
  pageSize: number,
  archived?: boolean,
  search?: string,
  assignableToBill = true
) =>
  await getRequest<IListAccounts>(
    `${API_URL.CLIENTS_BILLING}/${clientGuid}/entities/${entityGuid}/accounts?search=${
      search || ''
    }`,
    {
      page,
      page_size: pageSize,
      archived: archived,
      assignable_to_bill: assignableToBill,
    }
  )

export const updateClientBillRevise = async ({
  guid,
  billGuid,
  ...payload
}: IOrgBillReviseRequest) =>
  await postRequest(`${API_URL.CLIENTS_BILLING}/${guid}/bills/${billGuid}/workflow/revise`, {
    reason: payload.reason,
  })
// CLIENT USER API

export const listAssignableClientUsers = async (
  clientGuid: string,
  page?: number,
  page_size?: number,
  search?: string
) =>
  await getRequest<IListUserResponse>(`${API_URL.CLIENTS}/${clientGuid}/assignable-users`, {
    page,
    page_size,
    search,
  })

export const listClientUsers = async (clientGuid: string, params: IListUserRequest) =>
  await getRequest<IListClientUsersResponse, IListUserRequest>(
    `${API_URL.CLIENTS}/${clientGuid}/users`,
    params
  )

export const retrieveClientUser = async (clientGuid: string, userGuid: string) =>
  await getRequest<IOrgUser>(`${API_URL.CLIENTS}/${clientGuid}/users/${userGuid}`)

export const createClientUser = async ({
  clientGuid,
  user,
  role,
  should_update,
  client_relation,
  client,
}: {
  clientGuid: string
  user: IUserRequest | string
  role: ERole | string
  should_update?: boolean
  client_relation?: string
  client?: string
}) =>
  await postRequest<{
    user: IUserRequest | string
    role: ERole | string
    should_update?: boolean
    client_relation?: string
    client?: string
  }>(`${API_URL.CLIENTS}/${clientGuid}/users`, {
    user,
    role,
    should_update,
    client_relation,
    client,
  })

export const partialUpdateClientUser = async ({
  clientGuid,
  userGuid,
  role,
  user,
  client_relation,
  should_update,
}: IUpdateClientUserRequest) =>
  await patchRequest(`${API_URL.CLIENTS}/${clientGuid}/users/${userGuid}`, {
    role,
    user,
    client_relation,
    should_update,
  })

export const destroyClientUser = async ({
  clientGuid,
  userGuid,
}: {
  clientGuid: string
  userGuid: string
}) => await deleteRequest(`${API_URL.CLIENTS}/${clientGuid}/users/${userGuid}`)

export const enableClientUser = async ({
  clientGuid,
  userGuid,
}: {
  clientGuid: string
  userGuid: string
}) => await putRequest(`${API_URL.CLIENTS}/${clientGuid}/users/${userGuid}/enable`)

// CLIENT USER INVITATIONS API

export const listClientUserInvitations = async (clientGuid: string, page: number, search: string) =>
  await getRequest<IListInvitationsResponse>(`${API_URL.CLIENTS}/${clientGuid}/user-invitations`, {
    page,
    search,
  })

export const createClientUserInvitation = async ({
  clientGuid,
  invitation,
}: ICreateClientInvitationRequest) =>
  await postRequest(`${API_URL.CLIENTS}/${clientGuid}/user-invitations`, invitation)

export const updateClientUserInvitation = async ({
  clientGuid,
  invGuid,
  invitation,
}: IUpdateClientInvitationRequest) =>
  await patchRequest(`${API_URL.CLIENTS}/${clientGuid}/user-invitations/${invGuid}`, invitation)

export const destroyClientUserInvitation = async ({
  clientGuid,
  invGuid,
}: {
  clientGuid: string
  invGuid: string
}) => await deleteRequest(`${API_URL.CLIENTS}/${clientGuid}/user-invitations/${invGuid}`)

export const resendClientUserInvitation = async ({
  clientGuid,
  invGuid,
}: {
  clientGuid: string
  invGuid: string
}): Promise<undefined> =>
  await postRequest(`${API_URL.CLIENTS}/${clientGuid}/user-invitations/${invGuid}/resend`, null)

export const sendClientUserWelcomeEmail = async ({
  guid,
  userGuid,
}: {
  guid: string
  userGuid: string
}): Promise<undefined> =>
  await postRequest(`${API_URL.CLIENTS}/${guid}/users/${userGuid}/send-welcome-email`, null)

// INTEGRATIONS API
export const createOutlookIntegration = async ({
  clientGuid,
  email_address,
  should_verify,
}: {
  clientGuid: string
  email_address: string
  should_verify?: boolean
}): Promise<ICreateEmailIntegrationResponse> =>
  await postRequest(`${API_URL.INTEGRATIONS}/outlook/mailboxes/${clientGuid}`, {
    email_address,
    should_verify,
  })

export const getOutlookClientIntegrationStatus = async (
  clientGuid: string
): Promise<ICreateEmailIntegrationResponse> =>
  await getRequest(`${API_URL.INTEGRATIONS}/outlook/mailboxes/client/${clientGuid}`)

export const getOutlookIntegrationStatus = async (
  clientGuid: string
): Promise<ICreateEmailIntegrationResponse> =>
  await getRequest(`${API_URL.INTEGRATIONS}/outlook/mailboxes/${clientGuid}`)

export const removeOutlookEmail = ({ clientGuid }: { clientGuid: string }) =>
  deleteRequest(`${API_URL.INTEGRATIONS}/outlook/mailboxes/${clientGuid}`)

export const updateOutlookIntegrationEmail = async ({
  clientGuid,
  payload,
}: {
  clientGuid: string
  payload: IOutlookEmailRequest
}) =>
  await patchRequest<IOutlookEmailRequest>(
    `${API_URL.INTEGRATIONS}/outlook/mailboxes/${clientGuid}`,
    payload
  )

export const verifyOutlookIntegrationEmail = async ({
  email_address,
}: {
  email_address: string
}): Promise<ICreateEmailIntegrationResponse> =>
  await postRequest(`${API_URL.INTEGRATIONS}/outlook/mailboxes/verify`, {
    email_address: email_address,
  })

export const qbDesktopBillList = (bills: string, page: number, page_size: number) =>
  getRequest<IQBListResponse>(`${API_URL.INTEGRATIONS}/qbdesktop/bills/`, {
    bills,
    page,
    page_size,
  })

export const qbDesktopBillRetrieve = (billGuid: string) =>
  getRequest<IQBBillData>(`${API_URL.INTEGRATIONS}/qbdesktop/bills/${billGuid}`)

export const qbDesktopBillSync = (billGuid: string) =>
  putRequest(`${API_URL.INTEGRATIONS}/qbdesktop/bills/${billGuid}/sync`)

export const qbDesktopBillReset = (billGuid: string) =>
  deleteRequest(`${API_URL.INTEGRATIONS}/qbdesktop/bills/${billGuid}/reset`)

export const qbDesktopUnclaimedCompanyFilesRetrieve = () =>
  getRequest<IListQBDCompanyFileResponse>(
    `${API_URL.INTEGRATIONS}/qbdesktop/unclaimed-company-files`
  )

export const qbdClaimCompanyFile = async ({
  company,
  entityGuid,
}: {
  company: string
  entityGuid: string
}) =>
  await patchRequest(`${API_URL.INTEGRATIONS}/qbdesktop/company-file/${company}`, {
    entity: entityGuid,
  })

export const qbdCompanyFileRetrieve = (entityGuid: string) =>
  getRequest<IQBDCompanyFileResponse>(
    `${API_URL.INTEGRATIONS}/qbdesktop/entities/${entityGuid}/company-file`
  )

export const qbdCompanyFileUpdate = ({ entityGuid, ...payload }: IQBDCompanyFileUpdateRequest) =>
  patchRequest<{ password: string }, IQBDCompanyFileResponse>(
    `${API_URL.INTEGRATIONS}/qbdesktop/entities/${entityGuid}/company-file`,
    payload
  )

export const qbOnlineOauthRedirect = async (entityGuid: string): Promise<IQBORedirectResponse> =>
  await getRequest(`${API_URL.INTEGRATIONS}/qbonline/entities/${entityGuid}/oauth/redirect-url`)

export const qbOnlineCompanyRetrieve = (entityGuid: string) =>
  getRequest<IQBOCompanyResponse>(`${API_URL.INTEGRATIONS}/qbonline/entities/${entityGuid}/company`)

export const qbOnlineBillList = (
  type: 'bporg' | 'client',
  guid: string,
  bills: string,
  page: number,
  page_size: number
) =>
  getRequest<IQBListResponse>(
    `${API_URL.INTEGRATIONS}/qbonline/${
      type === 'bporg' ? 'organizations' : 'clients'
    }/${guid}/bills`,
    {
      bills,
      page,
      page_size,
    }
  )

export const qbOnlineBillRetrieve = (type: 'bporg' | 'client', guid: string, billGuid: string) =>
  getRequest<IQBBillData>(
    `${API_URL.INTEGRATIONS}/qbonline/${
      type === 'bporg' ? 'organizations' : 'clients'
    }/${guid}/bills/${billGuid}`
  )

export const qbOnlineClientRetrieve = (clientGuid: string) =>
  getRequest<{ is_connected: boolean }>(
    `${API_URL.INTEGRATIONS}/qbonline/clients/${clientGuid}/connected`
  )

export const qbOnlineOauthConnect = async ({
  entityGuid,
  authCode,
  realmId,
}: {
  entityGuid: string
  authCode: string
  realmId: string
}): Promise<IQBOCompanyResponse> =>
  await putRequest(`${API_URL.INTEGRATIONS}/qbonline/entities/${entityGuid}/oauth/connect`, {
    realm_id: realmId,
    auth_code: authCode,
  })

export const qbOnlineSync = async ({
  entityGuid,
}: {
  entityGuid: string
}): Promise<IQBOCompanyResponse> =>
  await putRequest(`${API_URL.INTEGRATIONS}/qbonline/entities/${entityGuid}/company/sync`)

// ORG USER-CLIENT RELATIONSHIP API
export const listAssignedClients = async ({
  orgGuid,
  userGuid,
  page,
  page_size,
  for_dashboard,
}: IListOrgUserClients): Promise<IListOrgUserClientsResponse> =>
  await getRequest(`${API_URL.ORGANIZATIONS}/${orgGuid}/users/${userGuid}/clients`, {
    page,
    page_size,
    for_dashboard,
  })

export const listAssignedOrgClients = async ({
  orgGuid,
  userGuid,
  clientGuid,
  page,
  page_size,
}: IListOrgUserClients): Promise<IListOrgUserClientsResponse> =>
  await getRequest(`${API_URL.ORGANIZATIONS}/${orgGuid}/users/${userGuid}/clients/${clientGuid}`, {
    page,
    page_size,
  })

export const removeAssignedClient = async ({
  clientGuid,
  orgGuid,
  userGuid,
}: IOrgUserClientRequest) =>
  await deleteRequest(`${API_URL.ORGANIZATIONS}/${orgGuid}/users/${userGuid}/clients/${clientGuid}`)

export const assignClient = async ({
  clientGuid,
  orgGuid,
  userGuid,
  client,
}: IOrgUserClientAssignRequest): Promise<IUserClientResponse> =>
  await putRequest(`${API_URL.ORGANIZATIONS}/${orgGuid}/users/${userGuid}/clients/${clientGuid}`, {
    ...client,
  })

export const listOrgClientUsers = async (
  orgGuid: string,
  clientGuid: string,
  params: IListUserRequest
) =>
  await getRequest<IListOrgUserClientUsersResponse, IListUserRequest>(
    `${API_URL.ORGANIZATIONS}/${orgGuid}/clients/${clientGuid}/users`,
    params
  )

// CLIENT DESIGNEE API

export const listDesignatedEntities = (clientGuid: string, userGuid: string) =>
  getRequest<IListDesignatedEntityResponse>(
    `${API_URL.CLIENTS}/${clientGuid}/users/${userGuid}/designated_entities`
  )

export const listDesignee = (clientGuid: string, entityGuid: string, params: IListUserRequest) =>
  getRequest<IListDesigneeResponse>(
    `${API_URL.CLIENTS}/${clientGuid}/entities/${entityGuid}/designees`,
    params
  )

export const createDesignatedEntity = ({
  clientGuid,
  userGuid,
  ...payload
}: ICreateDesignatedEntityRequest) =>
  postRequest(`${API_URL.CLIENTS}/${clientGuid}/users/${userGuid}/designated_entities`, payload)

export const updateDesignatedEntity = ({
  clientGuid,
  userGuid,
  entityGuid,
  ...payload
}: IUpdateDesignatedEntityRequest) =>
  patchRequest(
    `${API_URL.CLIENTS}/${clientGuid}/users/${userGuid}/designated_entities/${entityGuid}`,
    payload
  )

export const destroyDesignatedEntity = ({
  clientGuid,
  userGuid,
  entityGuid,
}: IDestroyDesignatedEntityRequest) =>
  deleteRequest(
    `${API_URL.CLIENTS}/${clientGuid}/users/${userGuid}/designated_entities/${entityGuid}`
  )

export const createDesignatedVendor = ({
  clientGuid,
  userGuid,
  ...payload
}: ICreateDesignatedVendorRequest) =>
  postRequest(`${API_URL.CLIENTS}/${clientGuid}/users/${userGuid}/designated_vendors`, payload)

export const updateDesignatedVendor = ({
  clientGuid,
  userGuid,
  vendorGuid,
  ...payload
}: IUpdateDesignatedVendorRequest) =>
  patchRequest(
    `${API_URL.CLIENTS}/${clientGuid}/users/${userGuid}/designated_vendors/${vendorGuid}`,
    payload
  )

export const listDesignatedVendors = (clientGuid: string, userGuid: string) =>
  getRequest<IListDesignatedVendorResponse>(
    `${API_URL.CLIENTS}/${clientGuid}/users/${userGuid}/designated_vendors`
  )

export const destroyDesignatedVendor = ({
  clientGuid,
  userGuid,
  vendorGuid,
}: IDestroyDesignatedVendorRequest) =>
  deleteRequest(
    `${API_URL.CLIENTS}/${clientGuid}/users/${userGuid}/designated_vendors/${vendorGuid}`
  )
// GROUP API

export const listGroups = (orgGuid: string, page: number, page_size: number, search?: string) =>
  getRequest<IListGuidWithName>(`${API_URL.ORGANIZATIONS}/${orgGuid}/groups`, {
    page,
    page_size,
    search,
  })

export const createOrgGroup = ({ orgGuid, name }: { orgGuid: string; name: string }) =>
  postRequest(`${API_URL.ORGANIZATIONS}/${orgGuid}/groups`, { name })

export const destroyOrgGroup = ({ orgGuid, groupGuid }: { orgGuid: string; groupGuid: string }) =>
  deleteRequest(`${API_URL.ORGANIZATIONS}/${orgGuid}/groups/${groupGuid}`)

export const partialUpdateOrgGroup = async ({
  orgGuid,
  groupGuid,
  orgGroupName,
}: {
  orgGuid: string
  groupGuid: string
  orgGroupName: string
}) =>
  await patchRequest(`${API_URL.ORGANIZATIONS}/${orgGuid}/groups/${groupGuid}`, {
    name: orgGroupName,
  })

export const addOrgGroupUser = ({ orgGuid, groupGuid, user }: IAddUserInGroupRequest) =>
  postRequest(`${API_URL.ORGANIZATIONS}/${orgGuid}/groups/${groupGuid}/users`, { user })

export const destroyGroupUser = ({ orgGuid, groupGuid, userGuid }: IOrgGroupUserGuid) =>
  deleteRequest(`${API_URL.ORGANIZATIONS}/${orgGuid}/groups/${groupGuid}/users/${userGuid}`)

export const listGroupsUsers = (
  orgGuid: string,
  groupGuid: string,
  page: number,
  page_size: number
) =>
  getRequest<IListOrgGroupUserResponse>(
    `${API_URL.ORGANIZATIONS}/${orgGuid}/groups/${groupGuid}/users`,
    {
      page,
      page_size,
    }
  )

export const listGroupPermissions = (
  orgGuid: string,
  groupGuid: string,
  page?: number,
  page_size?: number
) =>
  getRequest<IListGroupPermissionResponse>(
    `${API_URL.ORGANIZATIONS}/${orgGuid}/groups/${groupGuid}/permissions`,
    { page, page_size }
  )

export const destroyGroupPermission = ({
  orgGuid,
  groupGuid,
  permissionSlug,
}: IOrgGroupPermissionSlug) =>
  deleteRequest(
    `${API_URL.ORGANIZATIONS}/${orgGuid}/groups/${groupGuid}/permissions/${permissionSlug}`
  )

export const createGroupPermission = ({
  orgGuid,
  groupGuid,
  permission,
}: ICreateOrgGroupPermissionCreateRequest) =>
  postRequest(`${API_URL.ORGANIZATIONS}/${orgGuid}/groups/${groupGuid}/permissions`, { permission })

export const bulkCreateGroupPermission = ({
  orgGuid,
  groupGuid,
  permissions,
}: IBulkCreateOrgGroupPermissionCreateRequest) =>
  postRequest(`${API_URL.ORGANIZATIONS}/${orgGuid}/groups/${groupGuid}/permissions/bulk-create`, {
    permissions,
  })

export const userOrgGroupList = (
  orgGuid: string,
  userGuid: string,
  page?: number,
  page_size?: number
) =>
  getRequest<IListUserOrgGroupPermission>(
    `${API_URL.ORGANIZATIONS}/${orgGuid}/users/${userGuid}/groups`,
    { page, page_size }
  )

// Reports Client API
export const clientListReports = async ({
  clientGuid,
  year,
  month,
  page,
  search,
  type,
  entity,
  clients,
}: IListReportsRequest) => {
  return await getRequest<
    IListReportsResponse,
    {
      page?: number
      page_size?: number
      search?: string
      year: string
      month?: string
      type?: string
      entity?: string
      clients?: string
    }
  >(`${API_URL.REPORTS_CLIENT}/${clientGuid}/reports`, {
    page,
    search: search,
    year: year,
    month,
    type,
    entity,
    clients,
  })
}

export const clientReportQuantitiesByYear = async ({
  clientGuid,
  page,
}: IReportsQuantitiesByYearRequest) => {
  return await getRequest<IReportsQuantitiesResponse>(
    `${API_URL.REPORTS_CLIENT}/${clientGuid}/quantities-by-year`,
    {
      page,
    }
  )
}

export const clientReportQuantitiesByMonth = async ({
  clientGuid,
  page,
  year,
}: IReportsQuantitiesByMonthRequest) => {
  return await getRequest<IReportsQuantitiesResponse>(
    `${API_URL.REPORTS_CLIENT}/${clientGuid}/quantities-by-month`,
    {
      page,
      year,
    }
  )
}

export const listClientReportsSummary = async (
  clientGuid: string,
  payload: IReportSummaryRequest
) =>
  await getRequest<IReportSummaryResponse>(
    `${API_URL.REPORTS_CLIENT}/${clientGuid}/reports/summary`,
    payload
  )

// Org Side API
export const orgClientUploadReport = async ({
  orgGuid,
  payload,
}: {
  orgGuid: string
  payload: FormData
}): Promise<IReport> => {
  return await postRequest<FormData>(`${API_URL.REPORTS_ORG}/${orgGuid}/reports`, payload)
}

export const orgClientSingleUploadReport = async ({
  orgGuid,
  reportGuid,
  payload,
}: {
  orgGuid: string
  reportGuid: string
  payload: FormData
}): Promise<IReport> => {
  return await postRequest<FormData>(
    `${API_URL.REPORTS_ORG}/${orgGuid}/reports/${reportGuid}/files`,
    payload
  )
}

export const destroyOrgClientReport = async ({
  orgGuid,
  reportGuid,
}: {
  orgGuid: string
  reportGuid: string
}) => await deleteRequest(`${API_URL.REPORTS_ORG}/${orgGuid}/reports/${reportGuid}`)

export const destroyOrgReportFile = async ({
  orgGuid,
  reportGuid,
  id,
}: {
  orgGuid: string
  reportGuid: string
  id: number
}) => await deleteRequest(`${API_URL.REPORTS_ORG}/${orgGuid}/reports/${reportGuid}/files/${id}`)

export const orgClientListReports = async ({
  orgGuid,
  year,
  month,
  page,
  search,
  type,
  entity,
  clients,
}: IListReportsRequest) => {
  return await getRequest<
    IListReportsResponse,
    {
      page?: number
      page_size?: number
      search?: string
      year: string
      month?: string
      type?: string
      entity?: string
      clients?: string
    }
  >(`${API_URL.REPORTS_ORG}/${orgGuid}/reports`, {
    page,
    search: search,
    year: year,
    month: month,
    type,
    entity,
    clients,
  })
}

export const orgClientReportQuantitiesByYear = async ({
  orgGuid,
  clientGuid,
  page,
}: IReportsQuantitiesByYearRequest) => {
  return await getRequest<IReportsQuantitiesResponse>(
    `${API_URL.REPORTS_ORG}/${orgGuid}/quantities-by-year`,
    {
      page,
      client: clientGuid,
    }
  )
}

export const orgClientReportQuantitiesByMonth = async ({
  orgGuid,
  clientGuid,
  page,
  year,
}: IReportsQuantitiesByMonthRequest) => {
  return await getRequest<IReportsQuantitiesResponse>(
    `${API_URL.REPORTS_ORG}/${orgGuid}/quantities-by-month`,
    {
      page,
      year,
      client: clientGuid,
    }
  )
}

export const orgNotificationPreferences = async () => {
  return await getRequest<INotifications>(`${API_URL.NOTIFICATIONS}/preferences`)
}

export const orgUpdateNotificationPreferences = async (
  payload: INotifications
): Promise<INotifications> =>
  await patchRequest<INotifications>(`${API_URL.NOTIFICATIONS}/preferences`, payload)

// CLIENT COMMITMENTS
export const listClientCommitments = async (clientGuid: string, payload: ICommitmentsRequest) =>
  await getRequest<IListCommitments>(
    `${API_URL.CLIENTS_BILLING}/${clientGuid}/commitments`,
    payload
  )

export const listClientCommitmentsInstances = async (
  clientGuid: string,
  payload: ICommitmentsRequest
) =>
  await getRequest<IListAssociateCommitments>(
    `${API_URL.CLIENTS_BILLING}/${clientGuid}/commitments/instances`,
    payload
  )

export const getCommitmentCashOutflow = async (guid: string, payload: ICommitmentsRequest) =>
  await getRequest<ICommitmentCashOutflow>(
    `${API_URL.CLIENTS_BILLING}/${guid}/commitments/cash-outflow`,
    payload
  )

export const getMonthlyOverview = async (guid: string) =>
  await getRequest<IClientMonthlyOverview>(
    `${API_URL.CLIENTS_BILLING}/${guid}/commitments/monthly-overview`
  )

// ORG COMMITMENTS
export const listOrgCommitments = async (orgGuid: string, payload: ICommitmentsRequest) =>
  await getRequest<IListCommitments>(`${API_URL.ORG_BILLING}/${orgGuid}/commitments`, payload)

export const listOrgCommitmentsInstances = async (orgGuid: string, payload: ICommitmentsRequest) =>
  await getRequest<IListAssociateCommitments>(
    `${API_URL.ORG_BILLING}/${orgGuid}/commitments/instances`,
    payload
  )

export const listOrgCommitmentsTracker = async (
  orgGuid: string,
  payload: ICommitmentsTrackerRequest
) =>
  await getRequest<ICommitmentsMatrix>(
    `${API_URL.ORG_BILLING}/${orgGuid}/commitments/tracker`,
    payload
  )

export const createOrgCommitment = async ({
  orgGuid,
  payload,
}: {
  orgGuid: string
  payload: ICreateCommitmentRequest
}): Promise<ICommitments> => {
  return await postRequest<ICreateCommitmentRequest>(
    `${API_URL.ORG_BILLING}/${orgGuid}/commitments`,
    payload
  )
}

export const getOrgCommitmentDetails = async (orgGuid: string, commitmentGuid: string) =>
  await getRequest<ICommitments>(`${API_URL.ORG_BILLING}/${orgGuid}/commitments/${commitmentGuid}`)

export const listOrgCommitmentBills = async (
  orgGuid: string,
  commitmentGuid: string,
  payload?: IListCommitmentBillRequest
) =>
  await getRequest<IListCommitmentBillsResponse>(
    `${API_URL.ORG_BILLING}/${orgGuid}/commitments/${commitmentGuid}/bills`,
    payload
  )

export const createOrgCommitmentBill = async ({
  orgGuid,
  commitmentGuid,
  payload,
}: ICommitmentBillRequest) =>
  await postRequest<ICommitmentBillRequestPayload, null, ICommitmentBill>(
    `${API_URL.ORG_BILLING}/${orgGuid}/commitments/${commitmentGuid}/bills`,
    payload
  )

export const getOrgCommitmentBillDetails = async (
  orgGuid: string,
  commitmentGuid: string,
  id: string
) =>
  await getRequest<ICommitmentBill>(
    `${API_URL.ORG_BILLING}/${orgGuid}/commitments/${commitmentGuid}/bills/${id}`
  )

export const updateOrgCommitmentBill = async ({
  orgGuid,
  commitmentGuid,
  id,
  payload,
}: ICommitmentBillRequest) =>
  await patchRequest<ICommitmentBillRequestPayload, ICommitmentBill>(
    `${API_URL.ORG_BILLING}/${orgGuid}/commitments/${commitmentGuid}/bills/${id}`,
    payload
  )

export const listOrgCommitmentNotes = async (orgGuid: string, commitmentGuid: string) =>
  await getRequest<IListCommitmentNotesResponse>(
    `${API_URL.ORG_BILLING}/${orgGuid}/commitments/${commitmentGuid}/notes`
  )

export const createOrgCommitmentNote = async ({
  orgGuid,
  commitmentGuid,
  body,
  note_type,
}: ICommitmentNoteRequest) =>
  await postRequest(`${API_URL.ORG_BILLING}/${orgGuid}/commitments/${commitmentGuid}/notes`, {
    body,
    note_type,
  })

export const partialUpdateOrgCommitmentBill = async ({
  orgGuid,
  commitmentGuid,
  payload,
}: {
  orgGuid: string
  commitmentGuid: string
  payload: ICreateCommitmentRequest
}) => await patchRequest(`${API_URL.ORG_BILLING}/${orgGuid}/commitments/${commitmentGuid}`, payload)

export const generateCommitmentsTemplate = async ({
  orgGuid,
  payload,
}: IGenerateCommitmentsTemplateRequest) =>
  await postRequest<IGenerateCommitmentsTemplatePayload, { responseType: string }, Blob>(
    `${API_URL.ORG_BILLING}/${orgGuid}/commitments/generate-template`,
    payload,
    {
      responseType: 'blob',
    }
  )

export const removeClientCommitment = async ({
  orgGuid,
  commitmentGuid,
}: {
  orgGuid: string
  commitmentGuid: string
}) => await deleteRequest(`${API_URL.ORG_BILLING}/${orgGuid}/commitments/${commitmentGuid}`)

export const createCommitmentRecordPayment = async ({
  orgGuid,
  commitmentGuid,
  payload,
}: {
  orgGuid: string
  commitmentGuid: string
  payload: IRecordPaymentRequest
}) =>
  await postRequest<IRecordPaymentRequest, null, ICommitmentBill>(
    `${API_URL.ORG_BILLING}/${orgGuid}/commitments/${commitmentGuid}/create_autopay`,
    payload
  )

// CLIENT ACCOUNT BALANCE
export const listClientEntityAccountBalance = async (
  clientGuid: string,
  entityGuid: string,
  payload?: IBalanceSnapshotRequest
) =>
  await getRequest<IListAccountBalance>(
    `${API_URL.CLIENTS_BILLING}/${clientGuid}/entities/${entityGuid}/awaiting-approval`,
    payload
  )

// ORG ACCOUNT BALANCE
export const listOrgEntityAccountBalance = async (
  orgGuid: string,
  entityGuid: string,
  payload?: IBalanceSnapshotRequest
) =>
  await getRequest<IListAccountBalance>(
    `${API_URL.ORG_BILLING}/${orgGuid}/entities/${entityGuid}/awaiting-approval`,
    payload
  )

export const listOrgAccountBalanceList = async (
  orgGuid: string,
  {
    entityGuid,
    page,
    page_size,
    search,
  }: {
    entityGuid: string
    page?: number
    page_size?: number
    search?: string
  }
) =>
  await getRequest<IListAccountBalanceResponse>(
    `${API_URL.ORG_BILLING}/${orgGuid}/entities/${entityGuid}/account-balance-list`,
    {
      page,
      page_size,
      search,
    }
  )

export const bookBalanceSync = async ({
  entityGuid,
}: {
  entityGuid: string
}): Promise<undefined> => {
  return await postRequest(
    `${API_URL.INTEGRATIONS}/qbonline/entities/${entityGuid}/company/bookBalance/sync`,
    null
  )
}

export const updateEntityAccount = ({
  orgGuid,
  entityGuid,
  accountBankId,
  ...payload
}: IUpdateEntityAccountRequest) =>
  patchRequest(
    `${API_URL.ORG_BILLING}/${orgGuid}/entities/${entityGuid}/awaiting-approval/${accountBankId}`,
    payload
  )

// ORG REPORT REMINDER
export const listOrgReportReminders = async (orgGuid: string, payload: IReminderRequest) =>
  await getRequest<IListReportReminders>(
    `${API_URL.REPORTS_ORG}/${orgGuid}/report-reminders`,
    payload
  )

export const getOrgReportReminder = async (orgGuid: string, reportGuid: string) =>
  await getRequest<IReminder>(`${API_URL.REPORTS_ORG}/${orgGuid}/report-reminders/${reportGuid}`)

export const createOrgReportReminder = async ({
  orgGuid,
  payload,
}: {
  orgGuid: string
  payload: ICreateReminderRequest
}): Promise<IReminder> => {
  return await postRequest<ICreateReminderRequest>(
    `${API_URL.REPORTS_ORG}/${orgGuid}/report-reminders`,
    payload
  )
}

export const partialUpdateOrgReportReminder = async ({
  orgGuid,
  reportGuid,
  payload,
}: {
  orgGuid: string
  reportGuid: string
  payload: ICreateReminderRequest
}) =>
  await patchRequest(`${API_URL.REPORTS_ORG}/${orgGuid}/report-reminders/${reportGuid}`, payload)

export const getOrgReportRemindersHistory = async (orgGuid: string, payload: IReminderRequest) =>
  await getRequest<IListReportReminderHistory>(
    `${API_URL.REPORTS_ORG}/${orgGuid}/report-reminders-history`,
    payload
  )

export const createOrgReportReminderHistory = async ({
  orgGuid,
  payload,
}: {
  orgGuid: string
  payload: ICreateReminderHistoryRequest
}): Promise<IReminderHistory> => {
  return await postRequest<ICreateReminderHistoryRequest>(
    `${API_URL.REPORTS_ORG}/${orgGuid}/report-reminders-history`,
    payload
  )
}

export const destroyReportReminderHistory = async ({
  orgGuid,
  reportGuid,
}: {
  orgGuid: string
  reportGuid: string
}) =>
  await patchRequest(`${API_URL.REPORTS_ORG}/${orgGuid}/report-reminders-history/${reportGuid}`, {
    report_guid: reportGuid,
  })

export const getClientUserReportDetails = async (
  clientGuid: string,
  userGuid: string,
  payload: IClientReportRequest
) =>
  await getRequest<IListDesigneeReportResponse>(
    `${API_URL.CLIENTS}/${clientGuid}/designees/${userGuid}/excluded-reports`,
    payload
  )

// CLIENT IN APP NOTIFICATION
export const sendClientInAppNotification = async (payload: IClientInAppNotificationRequest) =>
  await postRequest(
    `${API_URL.NOTIFICATIONS}/clients/${payload.clientGuid}/entities/${payload.entityGuid}/awaiting-approval`,
    {
      recipients: payload.recipients,
      is_urgent: payload.isNotifyUrgent,
      is_funding_required: payload.isFundingRequired,
    }
  )

// ORG AUDIT HISTORY
export const getAuditHistoryList = async (orgGuid: string, payload: IAuditHistoryRequest) =>
  await getRequest<IListAuditHistoryResponse>(
    `${API_URL.ORGANIZATIONS}/${orgGuid}/event-logs`,
    payload
  )

export const createAuditHistory = async ({
  orgGuid,
  payload,
}: {
  orgGuid: string
  payload: ICreateAuditHistoryRequest
}) =>
  await postRequest<ICreateAuditHistoryRequest, null, IAuditHistory>(
    `${API_URL.ORGANIZATIONS}/${orgGuid}/event-logs`,
    payload
  )

// ORG PAR REPORT
export const sendParToClientEmail = async ({
  guid,
  clientGuid,
  payload,
}: {
  guid: string
  clientGuid: string
  payload: FormData
}): Promise<undefined> =>
  await postRequest(
    `${API_URL.ORG_BILLING}/${guid}/bills/attachments/send-par/${clientGuid}`,
    payload
  )

// ORG GET BILLS & EMAIL ATTACHMENTS
export const listBillAttachments = async (orgGuid: string, payload: IListBillRequest) =>
  await getRequest<IListBillAttachmentResponse>(
    `${API_URL.ORG_BILLING}/${orgGuid}/bills/attachments`,
    payload
  )

export const listEmailAttachments = async (orgGuid: string, payload: IListBillRequest) =>
  await getRequest<IListBillAttachmentResponse>(
    `${API_URL.INBOX_ORG}/${orgGuid}/emails/attachments`,
    payload
  )

// ORG DESIGNATED ENTITIES
export const listOrgDesignatedEntities = (
  orgGuid: string,
  userGuid: string,
  has_commitments?: boolean,
  has_bills_approved?: boolean,
  has_bills_waiting_approval?: boolean
) =>
  getRequest<IListEntityResponse>(
    `${API_URL.ORGANIZATIONS}/${orgGuid}/users/${userGuid}/designated_entities`,
    { has_commitments, has_bills_approved, has_bills_waiting_approval }
  )

// ASSOCIATE DASHBOARD
export const listAssociateCommitment = async (orgGuid: string, payload: ICommitmentsRequest) =>
  await getRequest<IListAssociateCommitments>(
    `${API_URL.ORG_BILLING}/${orgGuid}/associates/commitments`,
    payload
  )

export const associateCommitmentOverview = async (orgGuid: string) =>
  await getRequest<IUserDashboardChart>(
    `${API_URL.ORG_BILLING}/${orgGuid}/associates/commitments/overview`
  )

// MANAGER DASHBOARD
export const managerBillOverview = async (orgGuid: string) =>
  await getRequest<IBillDashboardChart>(`${API_URL.ORG_BILLING}/${orgGuid}/bills/overview`)

// RECONCILIATION
export const listOrgReconciliationReminders = async (orgGuid: string, payload: IReminderRequest) =>
  await getRequest<IListReportReminders>(
    `${API_URL.REPORTS_ORG}/${orgGuid}/report-reminders/reconciliations`,
    payload
  )

export const listOrgReconciliationWorkflow = async (orgGuid: string, payload: IReminderRequest) =>
  await getRequest<IListReconciliationWorkflow>(
    `${API_URL.REPORTS_ORG}/${orgGuid}/report-reminders/reconciliations`,
    payload
  )

export const listOrgReportReminderNotes = async (orgGuid: string, reportGuid: string) =>
  await getRequest<IListReminderNotesResponse>(
    `${API_URL.REPORTS_ORG}/${orgGuid}/report-reminders/${reportGuid}/notes`
  )

export const createOrgReportReminderNote = async ({
  orgGuid,
  reportGuid,
  body,
  note_type,
}: IReportReminderNoteRequest) =>
  await postRequest(`${API_URL.REPORTS_ORG}/${orgGuid}/report-reminders/${reportGuid}/notes`, {
    body,
    note_type,
  })

export const getOrgReconReminderHistory = async (
  orgGuid: string,
  reportGuid: string,
  historyId: string,
  payload: IReminderRequest
) =>
  await getRequest<IListReportReminderHistory>(
    `${API_URL.REPORTS_ORG}/${orgGuid}/report-reminders/${reportGuid}/history/${historyId}`,
    payload
  )

export const updateOrgReconReminderHistory = async ({
  orgGuid,
  reportGuid,
  historyId,
  payload,
}: {
  orgGuid: string
  reportGuid: string
  historyId: string
  payload: Partial<ICreateReminderHistoryRequest>
}) =>
  await patchRequest(
    `${API_URL.REPORTS_ORG}/${orgGuid}/report-reminders/${reportGuid}/history/${historyId}`,
    payload
  )

export const exportOrgReconciliationWorkflow = async (
  orgGuid: string,
  payload: IListReconciliationDownloadRequest
) =>
  await getRequest<Blob, IListReconciliationDownloadRequest>(
    `${API_URL.REPORTS_ORG}/${orgGuid}/report-reminders/reconciliations/export`,
    payload,
    'blob'
  )

// ONBOARDING
export const listOrgTaskLists = async (orgGuid: string, payload: ITaskListRequest) =>
  await getRequest<IListTaskItems>(`${API_URL.ORGANIZATIONS}/${orgGuid}/tasklist`, payload)

export const updateClientTaskList = async ({
  clientGuid,
  taskGuid,
  payload,
}: {
  clientGuid: string
  taskGuid: string
  payload: ITaskItemRequest
}) => await patchRequest(`${API_URL.CLIENTS}/${clientGuid}/tasks/${taskGuid}`, payload)

export const getClientTask = async (clientGuid: string, taskGuid: string) =>
  await getRequest<ITaskItems>(`${API_URL.CLIENTS}/${clientGuid}/tasks/${taskGuid}`)

// CLIENT EMAIL
export const listClientInboxEmails = async (clientGuid: string, payload: IListInboxEmailRequest) =>
  await getRequest<IListInboxEmails>(`${API_URL.INBOX_CLIENT}/${clientGuid}/emails`, payload)

// ONBOARDING CLIENT
export const listClientTaskLists = async (clientGuid: string, payload: ITaskListRequest) =>
  await getRequest<IListTaskItems>(`${API_URL.CLIENTS}/${clientGuid}/tasks`, payload)

export const listSummaryClientTaskLists = async (clientGuid: string) =>
  await getRequest<ITaskItemsSummaryResponse>(`${API_URL.CLIENTS}/${clientGuid}/tasks/summary`)

export const listOrgClientEmails = async (clientGuid: string) =>
  await getRequest<string[]>(`${API_URL.CLIENTS}/${clientGuid}/users/emails`)

export const listOrgClientDefaultUsers = async () =>
  await getRequest<IAssignedOnboarding[]>(`${API_URL.CONFIG}/default_onboarding_team`)

export const partialUpdateClientAssignedUsers = async ({
  orgGuid,
  userGuid,
  clientGuid,
  role,
  association,
  is_onboarding_only,
}: {
  orgGuid: string
  userGuid: string
  clientGuid: string
  role?: string
  association?: string
  is_onboarding_only?: boolean
}): Promise<IListUserResponse> =>
  await putRequest(`${API_URL.ORGANIZATIONS}/${orgGuid}/users/${userGuid}/clients/${clientGuid}`, {
    role,
    association,
    is_onboarding_only,
  })

export const deleteClientAssignedUsers = async ({
  orgGuid,
  userGuid,
  clientGuid,
}: {
  orgGuid: string
  userGuid: string
  clientGuid: string
}) =>
  await deleteRequest(`${API_URL.ORGANIZATIONS}/${orgGuid}/users/${userGuid}/clients/${clientGuid}`)

// ONBOARDING CONFIG
export const listTasks = async (orgGuid: string, payload: ITaskListRequest) =>
  await getRequest<IListTasksResponse>(`${API_URL.ORGANIZATIONS}/${orgGuid}/tasks`, payload)

export const createTask = async ({
  orgGuid,
  payload,
}: {
  orgGuid: string
  payload: ITaskCreateRequest
}) => await postRequest<ITaskCreateRequest>(`${API_URL.ORGANIZATIONS}/${orgGuid}/tasks`, payload)

export const importTasks = async ({ orgGuid, file }: { orgGuid: string; file: any }) => {
  const instance = await getAxiosInstance()
  const response = await instance.post(`${API_URL.ORGANIZATIONS}/${orgGuid}/tasks/import`, file, {
    headers: await getMultiPartHeaders(),
  })
  return response.data
}

export const listTaskRules = async (orgGuid: string, payload: ITaskListRequest) =>
  await getRequest<IListTaskRuleResponse>(`${API_URL.ORGANIZATIONS}/${orgGuid}/task-rules`, payload)

export const createTaskRule = async ({
  orgGuid,
  payload,
}: {
  orgGuid: string
  payload: ITaskRuleCreateRequest
}) =>
  await postRequest<ITaskRuleCreateRequest>(
    `${API_URL.ORGANIZATIONS}/${orgGuid}/task-rules`,
    payload
  )

export const updateTaskRule = async ({
  orgGuid,
  ruleGuid,
  payload,
}: {
  orgGuid: string
  ruleGuid: string
  payload: ITaskRuleCreateRequest
}) =>
  await patchRequest<ITaskRuleCreateRequest>(
    `${API_URL.ORGANIZATIONS}/${orgGuid}/task-rules/${ruleGuid}`,
    payload
  )

export const deleteTaskRule = async ({
  orgGuid,
  ruleGuid,
}: {
  orgGuid: string
  ruleGuid: string
}) => await deleteRequest(`${API_URL.ORGANIZATIONS}/${orgGuid}/task-rules/${ruleGuid}`)

export const importTaskRules = async ({ orgGuid, file }: { orgGuid: string; file: any }) => {
  const instance = await getAxiosInstance()
  const response = await instance.post(
    `${API_URL.ORGANIZATIONS}/${orgGuid}/task-rules/import`,
    file,
    {
      headers: await getMultiPartHeaders(),
    }
  )
  return response.data
}

// PROPERTIES
export const listClientProperties = async (clientGuid: string, payload: IPropertyListRequest) =>
  await getRequest<IListPropertyResponse>(`${API_URL.CLIENTS}/${clientGuid}/properties`, payload)

export const createClientProperty = async ({
  clientGuid,
  payload,
}: {
  clientGuid: string
  payload: IPropertyCreateRequest
}) =>
  await postRequest<IPropertyCreateRequest>(`${API_URL.CLIENTS}/${clientGuid}/properties`, payload)

export const updateClientProperty = async ({
  clientGuid,
  propertyGuid,
  payload,
}: {
  clientGuid: string
  propertyGuid: string
  payload: IPropertyCreateRequest
}) =>
  await patchRequest<IPropertyCreateRequest>(
    `${API_URL.CLIENTS}/${clientGuid}/properties/${propertyGuid}`,
    payload
  )

export const deleteClientProperty = async ({
  clientGuid,
  propertyGuid,
}: {
  clientGuid: string
  propertyGuid: string
}) => await deleteRequest(`${API_URL.CLIENTS}/${clientGuid}/properties/${propertyGuid}`)

// CONTACTS
export const listClientContacts = async (clientGuid: string, payload: IClientContactRequest) =>
  await getRequest<IListClientContactResponse>(`${API_URL.CLIENTS}/${clientGuid}/contacts`, payload)

export const createClientContact = async ({
  clientGuid,
  payload,
}: {
  clientGuid: string
  payload: IClientContactCreate
}) => await postRequest<IClientContactCreate>(`${API_URL.CLIENTS}/${clientGuid}/contacts`, payload)

export const updateClientContact = async ({
  clientGuid,
  contactGuid,
  payload,
}: {
  clientGuid: string
  contactGuid: string
  payload: IClientContactCreate
}) =>
  await patchRequest<IClientContactCreate>(
    `${API_URL.CLIENTS}/${clientGuid}/contacts/${contactGuid}`,
    payload
  )

export const deleteClientContact = async ({
  clientGuid,
  contactGuid,
}: {
  clientGuid: string
  contactGuid: string
}) => await deleteRequest(`${API_URL.CLIENTS}/${clientGuid}/contacts/${contactGuid}`)

// ONBOARDING DOCS
export const listClientOnboardingDocs = async (clientGuid: string, payload: IDocsRequest) =>
  await getRequest<IListOnboardingDocsResponse>(
    `${API_URL.CLIENTS}/${clientGuid}/documents`,
    payload
  )

export const retrieveClientOnboardingDocs = async (
  clientGuid: string,
  documentGuid: string,
  payload: IDocsRequest
) =>
  await getRequest<IOnboardingDocs>(
    `${API_URL.CLIENTS}/${clientGuid}/documents/${documentGuid}`,
    payload
  )

export const createOnboardingDocs = async ({
  clientGuid,
  file,
}: {
  clientGuid: string
  file: any
}) => {
  const instance = await getAxiosInstance()
  const response = await instance.post(`${API_URL.CLIENTS}/${clientGuid}/documents`, file, {
    headers: await getMultiPartHeaders(),
  })
  return response.data
}

export const updateClientOnboardingDocs = async ({
  clientGuid,
  documentGuid,
  payload,
}: {
  clientGuid: string
  documentGuid: string
  payload: IDocsRequest
}) =>
  await patchRequest<IDocsRequest>(
    `${API_URL.CLIENTS}/${clientGuid}/documents/${documentGuid}`,
    payload
  )

export const deleteClientOnboardingDocs = async ({
  clientGuid,
  documentGuid,
}: {
  clientGuid: string
  documentGuid: string
}) => await deleteRequest(`${API_URL.CLIENTS}/${clientGuid}/documents/${documentGuid}`)

export const sendOnboardingInvite = async ({
  orgGuid,
  clientGuid,
}: {
  orgGuid: string
  clientGuid: string
}) =>
  await postRequest(
    `${API_URL.ORGANIZATIONS}/${orgGuid}/clients/${clientGuid}/send-onboarding-invite`,
    {}
  )

// BANK CONTACTS
export const listClientBankContacts = async (clientGuid: string, payload: IClientContactRequest) =>
  await getRequest<IListClientBankContactResponse>(
    `${API_URL.CLIENTS}/${clientGuid}/bank-contacts`,
    payload
  )

export const createClientBankContact = async ({
  clientGuid,
  payload,
}: {
  clientGuid: string
  payload: IClientBankContactCreate
}) =>
  await postRequest<IClientBankContactCreate>(
    `${API_URL.CLIENTS}/${clientGuid}/bank-contacts`,
    payload
  )

export const updateClientBankContact = async ({
  clientGuid,
  bankGuid,
  payload,
}: {
  clientGuid: string
  bankGuid: string
  payload: IClientBankContactCreate
}) =>
  await patchRequest<IClientBankContactCreate>(
    `${API_URL.CLIENTS}/${clientGuid}/bank-contacts/${bankGuid}`,
    payload
  )

export const deleteClientBankContact = async ({
  clientGuid,
  bankGuid,
}: {
  clientGuid: string
  bankGuid: string
}) => await deleteRequest(`${API_URL.CLIENTS}/${clientGuid}/bank-contacts/${bankGuid}`)

// CLIENT USER ONBOARDING
export const retrieveClientOnboarding = async (clientGuid: string) =>
  await getRequest<IClient>(`${API_URL.CLIENTS}/${clientGuid}`)

export const partialUpdateClientOnboarding = async ({
  clientGuid,
  ...payload
}: IClientUpdateRequest) => await patchRequest(`${API_URL.CLIENTS}/${clientGuid}`, payload)

export const listParentVendors = async (params?: IListParentVendorsRequest) =>
  await getRequest<IListVendors>(`${API_URL.ORG_PARENT_VENDORS}`, params)

export const listSimilarParentVendors = async (guid: string, params?: IListParentVendorsRequest) =>
  await getRequest<IListVendors>(
    `${API_URL.ORG_PARENT_VENDORS}/${guid}/similar-parent-vendors`,
    params
  )

export const listVendorsOfParentVendor = async (
  guid: string,
  params?: IListVendorsOfParentVendorRequest
) => await getRequest<IListVendors>(`${API_URL.ORG_VENDORS}/parent-vendors/${guid}/vendors`, params)

export const retrieveParentVendor = async (guid: string) =>
  await getRequest<IVendor>(`${API_URL.ORG_PARENT_VENDORS}/${guid}`)

export const createParentVendor = async ({
  addr1,
  addr2,
  city,
  country,
  name,
  postal_code,
  state,
  primary_phone,
  vendor_type,
  tax_id_number,
}: ICreateParentVendorRequest) =>
  await postRequest(`${API_URL.ORG_PARENT_VENDORS}`, {
    addr1,
    addr2,
    city,
    country,
    name,
    postal_code,
    state,
    primary_phone,
    vendor_type,
    tax_id_number,
  })

export const createParentVendorFromVendor = async ({
  ve_guid,
  payload,
}: {
  ve_guid: string
  payload: ICreateParentVendorRequest
}) =>
  await postRequest(`${API_URL.ORG_PARENT_VENDORS}/create_parent_vendor_from_vendor/${ve_guid}`, {
    payload,
  })

export const updateParentVendor = async ({
  guid,
  payload,
}: {
  guid: string
  payload: IUpdateParentVendorRequest
}) => await putRequest(`${API_URL.ORG_PARENT_VENDORS}/${guid}`, payload)

export const mergeParentVendor = async ({
  source_guid,
  target_guid,
}: {
  source_guid: string
  target_guid: string
}) => {
  const payload = {}

  return await postRequest(
    `${API_URL.ORG_PARENT_VENDORS}/${source_guid}/merge/${target_guid}`,
    payload
  )
}

export const createVendor = async ({ org_guid, entity_guid, payload }: ICreateVendorRequest) => {
  return await postRequest(
    `${API_URL.ORG_BILLING}/${org_guid}/entities/${entity_guid}/vendors`,
    payload
  )
}

export const connectToParentVendor = async ({
  org_guid,
  entity_guid,
  p_ve_guid,
  v_guid,
  addr1,
  addr2,
  city,
  country,
  name,
  postal_code,
  state,
  parent_vendor_id,
}: {
  org_guid: string
  entity_guid: string
  p_ve_guid: string
  v_guid: string
  addr1: string
  addr2: string
  city: string
  country: string
  name: string
  postal_code: string
  state: string
  parent_vendor_id: number
}) => {
  return await patchRequest(
    `${API_URL.ORG_BILLING}/${org_guid}/entities/${entity_guid}/vendors/${v_guid}/connect-to-parent/${p_ve_guid}`,
    {
      addr1,
      addr2,
      city,
      country,
      name,
      postal_code,
      state,
      parent_vendor_id,
    }
  )
}

export const updateVerification = async ({
  guid,
  verification_type,
  payload,
}: {
  guid: string
  verification_type: string
  payload: FormData
}) => {
  return await postRequest(
    `${API_URL.ORG_PARENT_VENDORS}/${guid}/verification/${verification_type}`,
    payload
  )
}

// EXPORT COMMITMENT
export const exportClientCommitmentList = async (
  clientGuid: string,
  payload: IListCommitmentDownloadRequest
) =>
  await getRequest<Blob, IListCommitmentDownloadRequest>(
    `${API_URL.CLIENTS_BILLING}/${clientGuid}/commitments/instances/export`,
    payload,
    'blob'
  )

export const exportOrgCommitmentList = async (
  orgGuid: string,
  payload: IListCommitmentDownloadRequest
) =>
  await getRequest<Blob, IListCommitmentDownloadRequest>(
    `${API_URL.ORG_BILLING}/${orgGuid}/commitments/instances/export`,
    payload,
    'blob'
  )

// EXPORT CLIENT LIST
export const exportOrgClientList = async (orgGuid: string, payload: IListClientDownloadRequest) =>
  await getRequest<Blob, IListClientDownloadRequest>(
    `${API_URL.ORGANIZATIONS}/${orgGuid}/clients/export`,
    payload,
    'blob'
  )

// EXPORT PRE-BILL
export const exportOrgPrebillList = async (orgGuid: string, payload: IListInboxEmailRequest) =>
  await getRequest<Blob, IListInboxEmailRequest>(
    `${API_URL.INBOX_ORG}/${orgGuid}/emails/export`,
    payload,
    'blob'
  )

export const exportClientPrebillList = async (
  clientGuid: string,
  payload: IListPreBillDownloadRequest
) =>
  await getRequest<Blob, IListPreBillDownloadRequest>(
    `${API_URL.INBOX_CLIENT}/${clientGuid}/emails/export`,
    payload,
    'blob'
  )

export const listPlumbPins = async (
  client_guid: string,

  params?: IListPlumbPinsRequest
) => await getRequest<IPlumbPin>(`${API_URL.PLUMB_PINS}/${client_guid}/pins`, params)

export const createPlumbPin = async ({
  client_guid,

  payload,
}: {
  client_guid: string

  payload: ICreatePlumbPin
}) => await postRequest<ICreatePlumbPin>(`${API_URL.PLUMB_PINS}/${client_guid}/pins`, payload)

export const updatePlumbPin = async ({
  client_guid,

  guid,
  payload,
}: {
  client_guid: string

  guid: string
  payload: ICreatePlumbPin
}) => await patchRequest(`${API_URL.PLUMB_PINS}/${client_guid}/pins/${guid}/`, payload)

export const updateContactPlumbPin = async ({
  guid,
  client_guid,

  payload,
}: {
  guid: string
  client_guid: string

  payload: ICreatePlumbPin
}) =>
  await postRequest<ICreatePlumbPin>(
    `${API_URL.PLUMB_PINS}/${client_guid}/pins/contact/${guid}`,
    payload
  )

export const updatePropertyPlumbPin = async ({
  guid,
  client_guid,

  payload,
}: {
  guid: string
  client_guid: string

  payload: ICreatePlumbPin
}) =>
  await postRequest<ICreatePlumbPin>(
    `${API_URL.PLUMB_PINS}/${client_guid}/pins/property/${guid}`,
    payload
  )

export const updateEntityPlumbPin = async ({
  guid,
  client_guid,

  payload,
}: {
  guid: string
  client_guid: string

  payload: ICreatePlumbPin
}) =>
  await postRequest<ICreatePlumbPin>(
    `${API_URL.PLUMB_PINS}/${client_guid}/pins/entity/${guid}`,
    payload
  )

export const listReconciliationSummary = async (
  orgGuid: string,
  params: IReconciliationSummaryRequest
) =>
  await getRequest<IReconciliationSummaryResponse>(
    `${API_URL.REPORTS_ORG}/${orgGuid}/report-reminders/reconciliations/summary`,
    params
  )

export const bulkUpdateReconciliationTask = async ({
  orgGuid,
  payload,
}: {
  orgGuid: string
  payload: IReconciliationTaskBulkUpdate
}) =>
  await postRequest<IReconciliationTaskBulkUpdate>(
    `${API_URL.REPORTS_ORG}/${orgGuid}/report-reminders/reconciliations/tasks`,
    payload
  )

export const moveBackReconciliationTask = async ({
  orgGuid,
  payload,
}: {
  orgGuid: string
  payload: IReconciliationTaskBulkUpdate
}) =>
  await postRequest<IReconciliationTaskBulkUpdate>(
    `${API_URL.REPORTS_ORG}/${orgGuid}/report-reminders/reconciliations/tasks/move-back`,
    payload
  )
